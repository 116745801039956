import React from "react"
import Layout from "../components/layout"
import { charte } from "../components/charte"
import openmag from "../icones/open-magazine.svg"
import videoCamera from "../icones/video-camera.svg"
import brand from "../icones/brand-primary.svg"
import newspaper from "../icones/newspaper-primary.svg"
import { graphql } from "gatsby"
import { Column, Table } from 'react-virtualized';
import 'react-virtualized/styles.css'


function getItem(item) {
    var fullname = { title : item.node.title, author : item.node.author, year: item.node.date, type:{type:item.node.type,url:item.node.url},
    comments:item.node.comments
    };
    return fullname;
}

function TypeRenderer({ cellData, }){
  if (cellData['type'] == 'book') {
    return( <a href={cellData['url']}>
      <img src={openmag} height={40}/>
      </a>
      );
  } else if (cellData['type'] == 'video') {
    return( <a href={cellData['url']}>
        <img src={videoCamera} height={40}/>
      </a>
    );
  } else if (cellData['type'] == 'article') {
    return( <a href={cellData['url']}>
        <img src={newspaper} height={40}/>
      </a>
    );
  }
}
function CellRenderer({ cellData, }){
  return(
    <div
      style={{
        whiteSpace: 'normal',
      }}>
      {cellData}
    </div>
  )
}


class Resources extends React.Component {
    render() {
        const list = this.props.data.allLettersCsv.edges
        return(
          <Layout page={"Resources"}>
            <h1 align="center" style={{color: charte.primary}}>Resources</h1>
            <h3 align="center" style={{color: charte.dark}}>Here are some useful resources related to climate change.</h3>
            <h3 align="center" style={{color: charte.dark}}>They are mainly taken from the IBENS ressources list.</h3>
          <Table
            width={1000}
            height={500}
            headerHeight={20}
            rowHeight={60}
            rowCount={list.length}
            rowGetter={({ index }) => getItem(list[index])}
            style={{padding :40,}}
            headerStyle={{color: charte.primary}}
          >
            <Column
              width={60}
              label=''
              dataKey='type'
              cellRenderer={TypeRenderer}
            />
            <Column
              label='Title'
              dataKey='title'
              width={500}
              cellRenderer={CellRenderer}
            />
            <Column
              width={300}
              label='Author'
              dataKey='author'
            />
            <Column
              width={200}
              label='Year'
              dataKey='year'
            />
            <Column
              width={200}
              label='Comments'
              dataKey='comments'
            />
          </Table>

          </Layout>
        )
    }
}


export default Resources

export const IndexQuery = graphql`

  query {
    allLettersCsv {
      edges {
        node {
          title
          author
          date
          url
          comments
          type
        }
      }
    }
  }
`